.loadingbtn {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.loadingbtn::before {
  display: inline-block;
  width: 1.1rem;
  height: 1.1rem;
  margin-right: 0.5rem;
  clip-path: polygon(0% 0%, 100% 0%, 100% 30%, 0% 30%);
  color: red;
  vertical-align: -10%;
  content: '';
  border: 2px solid red;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

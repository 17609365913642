.progress {
  position: relative;
  width: 100%;
  height: 6px;
  background: rgb(255 255 255 / 20%);
  border-radius: 3px;
}

.overlay {
  height: 6px;
  border-radius: 3px;
}

.mark {
  position: absolute;
  width: 0.063rem;
  height: 0.5rem;

  &::after {
    position: absolute;
    left: 50%;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    transform: translateX(-50%);
  }
}

.max {
  top: 0.5rem;
  background: #e921c3;

  &::after {
    top: 0.375rem;
    color: #e921c3;
    content: 'Max';
  }
}

.liquidation {
  bottom: 0.5rem;
  background: #ff143f;

  &::after {
    bottom: 0.375rem;
    color: #ff143f;
    content: 'Liquidation';
  }
}

.noty {
  position: fixed;
  top: 100px;
  right: 16px;
  z-index: 99;
}
@media (max-width: 768px) {
  .noty {
    top: 84px;
  }
}
.container {
  display: flex;
  align-items: center;
  min-width: min(50vw, 400px);
  max-width: 400px;
  min-height: 50px;
  padding: 13px 16px;
  margin-bottom: 16px;
  border-radius: 8px;
}

.success {
  background: linear-gradient(90deg, #e921c3 0%, #0067ff 100%);
  background-color: linear-gradient(90deg, #e921c3 0%, #0067ff 100%);
}

.warn {
  background: #fbac14;
  background-color: #fbac14;
}

.error {
  background: #e63757;
  background-color: #e63757;
}

.icon {
  width: 24px;
  height: 24px;
}

.content {
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  color: #fff;
}

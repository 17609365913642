.container {
  position: relative;
  width: 100%;
  height: 62px;
  margin-bottom: 16px;
  background: #202122;
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: 0.125rem;

  &:focus-within {
    border: 1px solid #e921c3;
  }
}

.input {
  position: absolute;
  bottom: 8px;
  left: 16px;
  z-index: 0;
  display: inline-block;
  width: 70%;
  height: 26px;
  padding: 0 !important;
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  color: #fff;
  background-color: #202122 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;

  &::placeholder {
    color: rgb(255 255 255 / 50%);
  }
  &[disabled] {
    cursor: not-allowed;
  }
}

.prefix {
  position: absolute;
  top: 2px;
  left: 16px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: rgb(255 255 255 / 50%);
}

.suffix {
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  transform: translateY(-50%);
}

.pack {
  @apply col-span-1 flex w-full justify-center rounded-[8px] bg-gradient-to-t from-[#0067ff] to-[#e921c3] p-[1px];
}

.box {
  @apply z-30 flex min-h-[37rem] w-[21rem] flex-col items-center  rounded-lg border-[0.06rem] border-solid border-white/10 bg-[#000] p-6 md:min-h-[30.62rem] md:w-[34.5rem];
}

.airdroparea {
  @apply relative mb-[1.5rem];
}

.airdrop {
  @apply w-[20rem];
}

@media (max-width: 320px) {
  .box {
    @apply z-30 flex min-h-[250px] w-[256px] flex-col items-center  rounded-lg border-[.96px] border-solid border-white/10 bg-[#000] p-6;
  }

  .airdrop {
    @apply w-[full];
  }
}

.coinleft {
  @apply absolute left-0 top-0 h-[3.5rem] w-[3.5rem] rounded-full border-[0.17rem] border-solid border-[#333] bg-black;
}

.coinright {
  @apply absolute left-[3rem] top-0 h-[3.5rem] w-[3.5rem] rounded-full border-[0.17rem] border-solid border-[#333] bg-black;
}

.h2 {
  @apply mb-[1.5rem] flex w-[18.44rem] items-center text-center text-[1.38rem] font-bold leading-[1.75rem] text-white md:w-max;

  font-family: DMSans-Regular, sans-serif;
}

.info1 {
  @apply mb-[1rem] grid w-full grid-cols-1 border-b-[0.06rem] border-b-white/10 pb-[0.5rem] text-white md:grid-cols-6;
}

.info2 {
  @apply mb-[1rem] flex w-full flex-row items-center justify-between border-b-[0.06rem] border-b-white/10 pb-[0.5rem];
}

.info3 {
  @apply mb-[0rem] flex w-full flex-row items-center justify-between pb-[0.5rem];
}

.stakeddata,
.rewardsdata {
  @apply text-[1rem] leading-[1.63rem] text-[#ffffff];

  font-family: DMSans-Regular, sans-serif;
}

.tvl,
.staked,
.rewards {
  @apply col-span-3 mb-[1rem]  flex flex-col text-[0.88rem] text-[#fff]/50 md:mb-0;

  font-family: DMSans-Regular, sans-serif;
}

.apyandaction {
  @apply col-span-3 flex items-center justify-between text-[0.88rem] text-[#fff]/50;
}

.data {
  font-family: DMSans-Bold, sans-serif;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(90deg, #e921c3 0%, #0067ff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @apply text-[1.88rem];
}

.btn {
  font-family: DMSans-Bold, sans-serif;
}

.btn1 {
  font-family: DMSans-Regular, sans-serif;
  @apply hover:border-[#E921C3] hover:bg-[#E921C3] hover:text-[#FFFFFF]  active:border-[#C31BA3] active:bg-[#C31BA3] active:text-[#FFFFFF];
}

.btn11 {
  font-family: DMSans-Regular, sans-serif;
  @apply hover:border-[#EF64D5] hover:text-[#EF64D5] active:border-[#C31BA3] active:text-[#C31BA3];
}

.btn2 {
  font-family: DMSans-Regular, sans-serif;
  @apply hover:border-[#4D94FF] hover:text-[#4D94FF] active:border-[#0056D6] active:text-[#0056D6];
}

.pools {
  @apply flex h-[6.37rem] w-[10rem] justify-center md:h-[10rem] md:w-[12.13rem];
}

.title {
  font-family: DMSans-Bold, sans-serif;
  background: linear-gradient(90deg, #e921c3 0%, #0067ff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @apply mt-10 text-[2.5rem] font-medium text-[#fff] md:text-[4.5rem];
}

.leftcircle {
  @apply fixed bottom-0 left-0 w-[37.5rem];
}

.rightcircle {
  @apply fixed right-0  top-0 w-[20rem];
}

.container {
  @apply min-h-screen;
}

@media (max-width: 639px) {
  .rightcircle {
    @apply hidden;
  }
}

.poolsarea {
  @apply grid grid-cols-1 gap-6 md:grid-cols-2;
}

.loading {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  clip-path: polygon(0% 0%, 100% 0%, 100% 30%, 0% 30%);
  border: 2px solid #e921c3;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: DMSans-Bold;
    font-weight: normal;
    src: url('./assets/fonts/DMSans-Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: DMSans-Medium;
    font-weight: normal;
    src: url('./assets/fonts/DMSans-Medium.ttf') format('truetype');
  }
  @font-face {
    font-family: DMSans-Regular;
    font-weight: normal;
    src: url('./assets/fonts/DMSans-Regular.ttf') format('truetype');
  }
}

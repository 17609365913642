.border {
  @apply col-span-1 -mr-px -mb-px flex h-[22.75rem] flex-col px-8 pt-12 pb-6;

  border-width: 1px;
  border-image: linear-gradient(180deg, rgb(233 33 195/ 100%), rgb(0 103 255/ 100%)) 1 1;
}

.partner {
  @apply grid w-full gap-4;

  grid-template-columns: repeat(3, minmax(0, 1fr));
}

/* @media (min-width: 397px) {
  .partner {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
} */

@media (min-width: 587px) {
  .partner {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 809px) {
  .partner {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media (min-width: 999px) {
  .partner {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
